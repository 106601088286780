import View from '../../common/view';

class LoginBodyView extends View {
  _parentEl = document.querySelector('.login--register--upload');

  constructor(handler) {
    super(handler);
    this._handlerSubmit(handler);
    this._handleKeypress();
  }

  _handlerSubmit(handler) {
    this._parentEl.addEventListener('submit', function (e) {
      e.preventDefault();

      const _addValidationClass = item => {
        document
          .querySelector(`.login--register--upload input[name=${item}]`)
          .closest('.form-floating')
          .classList.add('is-invalid');
        document
          .querySelector(`.login--register--upload input[name=${item}]`)
          .classList.add('is-invalid');
      };

      const step = e.target.querySelector('button[type=submit]').dataset.step;

      const dataArr = [...new FormData(this)];
      const data = Object.fromEntries(dataArr);
      let isValid = true,
        emailRegex =
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      dataArr.forEach(item => {
        if (item[0] === 'mobile' && item[1].length < 10) {
          _addValidationClass(item[0]);
          isValid = false;
        }
        if (item[0] === 'name' && item[1].length < 4) {
          _addValidationClass(item[0]);
          isValid = false;
        }
        if (item[0] === 'email' && !item[1].match(emailRegex)) {
          _addValidationClass(item[0]);
          isValid = false;
        }
        if (item[0] === 'otp' && item[1].length < 4) {
          _addValidationClass(item[0]);
          isValid = false;
        }
      });

      if (isValid) handler(data, +step);
    });
  }

  _handleKeypress(e) {
    this._parentEl.addEventListener('keypress', function (e) {
      const input = e.target.closest('input');

      if (!input) return;

      input.closest('.form-floating').classList.remove('is-invalid');
      input.classList.remove('is-invalid');
    });
  }

  _generateMarkup() {
    if (this._data.type === 'login') {
      return this._data.step === 1
        ? this._previewininitialPage()
        : this._previewSecondPage();
    } else {
      return this._data.step === 1
        ? this._previewRegisterInitialPage()
        : this._previewSecondPage();
    }
  }

  toggleLoadingBtn() {
    this._parentEl.querySelectorAll('.btn').forEach(btn => {
      btn.classList.toggle('d-none');
    });
  }

  _loadingBtn() {
    return `<button class="btn btn-warning-2 text-white btn--disable d-none" type="button" disabled>
      <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      <span role="status">Loading...</span>
    </button>`;
  }

  _inputMobile(value, disabled) {
    return `<div class="input-group has-validation">
        <div class="form-floating">
            <input
                type="tel"
                ${disabled ? 'disabled' : ''}
                class="form-control"
                id="login-mobile-number"
                placeholder="0123456789"
                maxlength="10"
                autocomplete="off"
                name="mobile"
                value="${value ? value : ''}"
            />
            <label for="#login-mobile">Mobile Number</label>
        </div>
        <div class="invalid-feedback">
            Enter 10 digit mobile nuber.
        </div>
    </div>`;
  }

  _input(type, name, value, disabled, placeholder, id) {
    return `<div class="input-group has-validation">
    <div class="form-floating mt-3">
        <input
            type="${type}"
            ${disabled ? 'disabled' : ''}
            class="form-control"
            id="${id}"
            maxlength="200"
            placeholder="${placeholder}"
            autocomplete="off"
            name="${name}"
            value="${value ? value : ''}"
        />
        <label class="text-capitalize" for="${id}">${name}</label>
    </div>
    <div class="invalid-feedback">
        ${name === 'name' ? 'Enter minimum 4 chars.' : 'Enter valid email.'}
        </div>
    </div>`;
  }

  _previewininitialPage() {
    return `${this._inputMobile(this._data.data.mobile, false)} 
            <div class="d-grid gap-2 mt-3">
                <button
                    class="btn btn-warning-2 text-white btn--login"
                    type="submit"
                    data-step="2"
                >
                    Login
                </button>
                ${this._loadingBtn()}
            </div>`;
  }

  _previewRegisterInitialPage() {
    return `${this._inputMobile(this._data.data.mobile, false)}
            ${this._input(
              'text',
              'name',
              this._data.data.name,
              false,
              'name',
              'register-name'
            )}
            ${this._input(
              'email',
              'email',
              this._data.data.email,
              false,
              'parking@email.com',
              'register-email'
            )}    
            <div class="d-grid gap-2 mt-3">
                <button
                    class="btn btn-warning-2 text-white btn--continue"
                    type="submit"
                    data-step="2"
                >
                    Continue
                </button>
                ${this._loadingBtn()}
            </div>`;
  }

  _previewSecondPage() {
    return `${this._inputMobile(this._data.data.mobile, true)}
            <div class="input-group has-validation">
                <div class="form-floating mt-3">
                    <input
                        type="text"
                        maxlength="4"
                        autocomplete="one-time-code"
                        inputmode="numeric"
                        class="form-control"
                        id="login-otp"
                        placeholder="otp"
                        name='otp'
                    />
                    <label for="login-otp">OTP</label>
                </div>
                <div class="invalid-feedback">
                    Enter 4 digit OTP.
                </div>
            </div>
            <div class="d-grid gap-2 mt-3">
                <button
                    class="btn btn-warning-2 text-white btn--verify"
                    type="submit"
                    data-step="3"
                >
                    Verify OTP
                </button>
                ${this._loadingBtn()}
            </div>`;
  }
}

export default LoginBodyView;
