import View from '../../common/view';
import arrowLeft from '../../../img/general/arrow-left.svg';

class LoginHeaderView extends View {
  _parentEl = document.querySelector('.login--header');
  _toggleEl = document.querySelector('.toggle--section');

  constructor(handler) {
    super(handler);
    this._addHandlerEvents(handler);
  }

  _addHandlerEvents(handler) {
    this._parentEl.addEventListener('click', function (e) {
      e.preventDefault();
      const btnToggle = e.target.closest('.toggle--section');
      const btnBack = e.target.closest('.btn--back');

      if (btnToggle || btnBack) {
        const type = btnToggle?.dataset?.toggle;
        let step = 1;

        if (btnBack?.dataset?.step) step = +btnBack.dataset.step;
        handler(type, step);
      }
    });
  }

  _generateMarkup() {
    return this._data.step === 1
      ? this._initialHeaderPage()
      : this._secondaryHeaderPage();
  }

  _initialHeaderPage() {
    return `
        <div>
            <h4
                class="offcanvas-title"
                id="login--signup--section--Label"
            >
                ${this._data.type === 'login' ? 'Login' : 'Sign up'}
            </h4>
            or
            <a href="#" class="text-warning-2 toggle--section" data-toggle="${
              this._data.type === 'login' ? 'register' : 'login'
            }">${
      this._data.type === 'login'
        ? 'create an account'
        : 'login to your account'
    }</a>
        </div>
        <button
            type="button"
            class="btn-close"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
        ></button>
    `;
  }

  _secondaryHeaderPage() {
    return `
        <div>
            <h4
                class="offcanvas-title"
                id="login--signup--section--Label"
            >
                Enter OTP
            </h4>
            <small>We've sent an OTP to your mobile number.</small>
        </div>
        <button type="button" class="btn btn--back" data-step="1">
            <img
                src="${arrowLeft}"
                alt="Logo"
                width="20"
                height="20"
                class="d-inline-block align-text-middle"
              />
        </button>
    `;
  }
}

export default LoginHeaderView;
