import * as globals from '../common/model.js';
import * as model from './model.js';

import LeafletView from '../leaflet/leafletViews.js';

import Tooltip from '../bootstrap/tooltip.js';
import Offcanvas from '../bootstrap/offcanvas.js';
import Toast from '../bootstrap/toast.js';
import Dropdown from '../bootstrap/dropdown.js';

import LoginHeaderView from './views/loginHeaderView.js';
import LoginBodyView from './views/loginBodyView.js';
import DropDownView from '../common/views/dropDownView.js';
import DropDownListView from '../common/views/dropDownListView.js';
import searchView from '../common/views/searchView.js';

import 'core-js/stable';
import 'regenerator-runtime/runtime';

let loginHeader;
let loginBody;
let dropDownStateView,
  dropDownCityView,
  dropDownStateListView,
  dropDownCityListView;
let leaftletview, offcanvasElList, pageId;
let toastBootstrap;

const redirectToHome = function () {
  try {
    window.history.replaceState(null, '', 'home.html');
    window.location.replace(window.location.href);
  } catch (error) {}
};

const controlCheckToken = function () {
  if (localStorage.getItem('token')) redirectToHome();
  else window.history.replaceState(null, '', 'index.html');
};

const controlOffcanvas = async function () {
  offcanvasElList = document.querySelectorAll('.offcanvas');
  offcanvasElList = [...offcanvasElList].map(
    offcanvasEl => new Offcanvas(offcanvasEl)
  );
};

const controlTooltip = async function () {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  const tooltipList = [...tooltipTriggerList].map(
    tooltipTriggerEl => new Tooltip(tooltipTriggerEl)
  );
};

const controlInitializeToast = function () {
  if (toastBootstrap) {
    toastBootstrap.dispose();
  }
  const toastEl = document.querySelector('.toast');
  toastBootstrap = Toast.getOrCreateInstance(toastEl);
};

const controlToggleLoginAction = async function (type, step) {
  try {
    await model.updateLoginAction(type ? type : model.state.login.type, step);
    // render
    const { login } = model.state;
    loginHeader.render(login);
    loginBody.render(login);
  } catch (error) {}
};

const controlLoginRegister = async function (data, step) {
  try {
    // togggle btn loader
    loginBody.toggleLoadingBtn();
    // call backend
    const resp = await model.sendVerifyOTP(data, step);
    // render
    const { login } = model.state;
    loginHeader.render(login);
    loginBody.render(login);
    // message
    loginBody.renderMessage(resp.message, undefined, 'beforeend');
    controlInitializeToast();
    // redirect
    controlCheckToken();
  } catch (error) {
    loginBody.toggleLoadingBtn();
    loginBody.renderError(error, undefined, 'beforeend');
    controlInitializeToast();
  }
};

const controlLoadLoginSection = async function () {
  try {
    const { login } = model.state;
    // initialize
    loginHeader = new LoginHeaderView(controlToggleLoginAction);
    loginBody = new LoginBodyView(controlLoginRegister);
    // render
    loginHeader.render(login);
    loginBody.render(login);
  } catch (error) {}
};

const controlinitStateView = async function () {
  const { state } = globals.state.dropDown;
  // state update
  dropDownStateListView.update(state);
};

const controlDropdownView = async function () {
  try {
    const { state, city } = globals.state.dropDown;
    dropDownStateView = new DropDownView();
    dropDownStateListView = new DropDownListView();
    dropDownStateView.initialize('state', state.selected, controlinitStateView);
    dropDownStateListView.initialize('state', state);
    // render city
    dropDownCityView = new DropDownView();
    dropDownCityListView = new DropDownListView();
    dropDownCityView.initialize('city', city.selected);
    dropDownCityListView.initialize('city', city);
  } catch (error) {}
};

const controlDropdownStateSelect = async function (value) {
  try {
    await globals.setDropdownValue(value, 'state');
    const { state, city } = globals.state.dropDown;
    // state update
    dropDownStateView.update(state.selected);
    dropDownStateListView.update(state);
    // city update & render
    dropDownCityView.update(city.selected);
    dropDownCityListView.update(city);
  } catch (error) {}
};

const controlDropdownCitySelect = async function (value) {
  try {
    const data = await globals.setDropdownValue(value, 'city');
    const { city } = globals.state.dropDown;

    // city update
    dropDownCityView.update(city.selected);
    dropDownCityListView.update(city);
  } catch (error) {}
};

const controlLoadLeaflet = async function () {
  try {
    const { state, city } = globals.state.dropDown;

    if (state.selected === 'select state') {
      throw new Error('select state');
    }

    if (city.selected === 'select city') {
      throw new Error('select city');
    }

    searchView.renderMessage('loaded map', undefined);

    const coords = [+city.obj.latitude, +city.obj.longitude];
    if (!coords) return;
    // update city lat,lon to leaflet views
    leaftletview.setViewTo(coords);
    const data = await model.getGeoJson({
      state_name: city.obj.state_name,
      id: city.obj._id,
    });
    const { geo_json } = model.state;
    leaftletview.data = geo_json;
    leaftletview.geoJson();
    controlInitializeToast();
  } catch (error) {
    searchView.renderError(error, undefined);
    controlInitializeToast();
  }
};

const controlPageLoad = async function () {
  try {
    pageId = window.location.hash.slice(1);

    if (!pageId) return;

    const params = new URLSearchParams(pageId.split('?')[1]);

    if (params.size) {
      // view post---login offcanvas
      offcanvasElList[0].toggle();
    }
  } catch (error) {}
};

const init = function () {
  controlCheckToken();
  controlOffcanvas();
  controlLoadLoginSection();
  controlDropdownView();
  dropDownStateListView.addhandlerSelect(controlDropdownStateSelect);
  dropDownCityListView.addhandlerSelect(controlDropdownCitySelect);
  // init leaflet
  leaftletview = new LeafletView('map');
  searchView.addhandlerSearchSubmit(controlLoadLeaflet);
  searchView.addHandlerRender(controlPageLoad);
};

init();
