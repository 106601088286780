import { API_URL, RES_PER_PAGE } from '../common/config.js';
import { getJSON } from '../common/helper.js';

import 'regenerator-runtime/runtime';

export const state = {
  login: {
    type: 'login',
    step: 1,
    data: {},
  },
  log: {},
};

const defaultLogin = {
  type: 'login',
  step: 1,
  data: {},
};

export const updateLoginAction = async function (type, step, data = {}) {
  try {
    return new Promise(resolve => {
      state.login = {
        ...state.login,
        type,
        step,
        data,
      };
      resolve(state);
    });
  } catch (error) {
    throw error;
  }
};

export const sendVerifyOTP = async function (data, step) {
  try {
    let dataObj = {
      ...state.login.data,
      ...data,
    };
    state.login = {
      ...state.login,
      step,
      data: dataObj,
    };

    const url =
      step === 3
        ? `${API_URL}/${state.login.type}/verify-otp`
        : `${API_URL}/${state.login.type}/otp`;
    const resp = await getJSON(url, { ...state.login.data, log: state.log });
    if (resp.token) {
      localStorage.setItem('token', resp.token);
      localStorage.setItem('_na', resp.name);
      state.login = {
        ...state.login,
        ...defaultLogin,
      };
    }
    return resp;
  } catch (error) {
    throw error;
  }
};

export const getGeoJson = async function (data) {
  try {
    const resp = await getJSON(`${API_URL}/geo/index`, data);
    state.geo_json = resp;
    return resp;
  } catch (error) {
    throw error;
  }
};

const log = async function () {
  try {
    state.log = {
      userAgent: navigator.userAgent,
      userAgentData: navigator.userAgentData,
      webdriver: navigator.webdriver,
      hardwareConcurrency: navigator.hardwareConcurrency,
      memory: navigator.deviceMemory,
      connection: {
        effectiveType: navigator.connection.effectiveType,
        rtt: navigator.connection.rtt,
        downlink: navigator.connection.downlink,
      },
      languages: navigator.languages,
      language: navigator.language,
    };
  } catch (error) {}
};

const init = async function () {
  log();
};

init();
